import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  ChangePassword = 'CHANGE_PASSWORD'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.ChangePassword]: undefined;
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/me');

          const { user, classId } = response.data;
          const userWithClassIds = { ...user, classId };

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: userWithClassIds
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    setSession(null);
    const response = await axios.post(`/login`, {
      email,
      password
    });
    const { accessToken, user, classId } = response.data;
    const userWithClassIds = { ...user, classId };
    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        // user
        user: userWithClassIds
      }
    });
  };

  const changePassword = async (payload: any) => {
    await axios.post(`/change-password`, payload);

    dispatch({
      type: Types.ChangePassword
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const loginAgency = (accessToken: string, user: any) => {
    console.log('LoginAgency', user);
    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const resetPassword = (email: string) => console.log(email);

  const verifyTeacher = async (qrCode: string, personalCode: string) => {
    setSession(null);
    const response = await axios.post(`/teacher/verify`, {
      qrCode,
      personalCode
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        loginAgency,
        resetPassword,
        changePassword,
        verifyTeacher
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
