import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Director, Student, StudentProgress } from '../../@types/school';
import { ListMeta, ListQueryParams } from '../../@types/table';

// ----------------------------------------------------------------------

type DirectorResponse = {
  data: any;
  type: string;
};

type DirectorState = {
  isLoading: boolean;
  error: any;
  director: null | Director;
  response: null | DirectorResponse;
  directorList: Director[];
  directorListMeta: ListMeta;
  studentProgress: StudentProgress[];
};

const initialState: DirectorState = {
  isLoading: false,
  error: false,
  director: null,
  response: null,
  directorList: [],
  directorListMeta: {} as ListMeta,
  studentProgress: []
};

const slice = createSlice({
  name: 'director',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START LOADING
    reset(state) {
      state.isLoading = false;
      state.error = false;
      state.response = null;
      state.director = null;
    },

    // START LOADING
    clearError(state) {
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
    },

    deleteSuccess(state, action) {
      state.isLoading = false;
      state.director = action.payload;
      state.response = {
        data: action.payload.data,
        type: 'DELETE_DIRECTOR_SUCCESS'
      };
    },

    updateSuccess(state, action) {
      state.isLoading = false;
      state.director = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_DIRECTOR_SUCCESS'
      };
    },

    createSuccess(state, action) {
      state.isLoading = false;
      state.director = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'CREATE_DIRECTOR_SUCCESS'
      };
    },

    getListSuccess(state, action) {
      state.isLoading = false;
      state.directorList = action.payload.data;
      state.directorListMeta = action.payload.meta as ListMeta;
      state.response = {
        data: action.payload.data,
        type: 'GET_DIRECTOR_LIST_SUCCESS'
      };
    },

    getSuccess(state, action) {
      state.isLoading = false;
      state.director = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_DIRECTOR_SUCCESS'
      };
    },
    getStudentProgress(state, action) {
      state.isLoading = false;
      state.studentProgress = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'GET_STUDENT_PROGRESS'
      };
    },
    // UPDATE director
    updateDirectorSuccess(state, action) {
      state.isLoading = false;
      state.director = action.payload.data;
      state.response = {
        data: action.payload.data,
        type: 'UPDATE_DIRECTOR_SUCCESS'
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// ----------------------------------------------------------------------

export function getDirectorByID(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/director/${recordId}`);
      dispatch(slice.actions.getSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDirectorList(params: ListQueryParams) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/directors/paginate', {
        params: {
          'pagination[pageSize]': params.pageSize,
          'pagination[page]': params.page
        }
      });
      dispatch(slice.actions.getListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function deleteDirector(recordId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/director/${recordId}`);
      dispatch(slice.actions.deleteSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDirector(recordId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/director/${recordId}`, payload);

      dispatch(slice.actions.updateSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createDirector(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/director/`, payload);
      dispatch(slice.actions.createSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset());
  };
}

export function updateDirectorSetting(directorId: number | null, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/director/setting/${directorId}`, payload);
      dispatch(slice.actions.updateDirectorSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getHighestStudentProgress(schoolId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/director/get/studentProgress/${schoolId}`);
      console.log('Response', response);
      dispatch(slice.actions.getStudentProgress(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// for guest
export function clearError() {
  return async () => {
    dispatch(slice.actions.clearError());
  };
}
